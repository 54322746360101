<template>
  <div>
    <Html class="h-full bg-gray-50 dark:bg-gray-900">
      <Head>
        <Title>WeTix - The all-in-one event ticketing platform.</Title>
      </Head>
    </Html>

    <Body class="h-full bg-white" />

    <main>
      <slot />
    </main>
  </div>
</template>
